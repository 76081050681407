import { render, staticRenderFns } from "./S3UploadElement.vue?vue&type=template&id=25ca60f8&scoped=true"
import script from "./S3UploadElement.vue?vue&type=script&lang=js"
export * from "./S3UploadElement.vue?vue&type=script&lang=js"
import style0 from "./S3UploadElement.vue?vue&type=style&index=0&id=25ca60f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ca60f8",
  null
  
)

export default component.exports