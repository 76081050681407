<template>
  <v-card tile>
    <v-input
      :append-icon="cancelIcon"
      @click:append="cancel()"
      prepend-icon="mdi-file"
    >
      {{ file.name + ' ' + (showFileSize === 'filename' ? calcFileSizeString() : '') }}
    </v-input>
    <v-progress-linear
      :value="progress"
      height="25"
      color="accent"
      background-color="accentContrast"
    >
      <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}% {{ showFileSize === 'progress' ? calcFileSizeString() : '' }}</strong>
      </template>
    </v-progress-linear>
  </v-card>
</template>

<script lang="js">
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { S3Helper } from '@/base/js/aws/S3Helper';

export default {
  name: 'S3UploadElement',
  props: {
    file: { type: File, required: true },
    s3Config: { type: Object, default: () => { return null; } },
    objectKey: { type: String, required: true },
    autoProcess: { type: Boolean, default: true },
    maxTries: { type: Number, default: 3 },
    cancelIcon: { type: String, default: 'mdi-close' },
    chunkConfig: { type: Object, default: () => { return null; } },
    showFileSize: { type: String, default: 'no' } // no, filename, progress
  },
  data () {
    return {
      progress: 0,
      uploadId: '',
      totalParts: 1,
      parts: [],
      s3objKey: ''
    };
  },
  computed: {
  },
  mounted () {
    if (this.autoProcess) this.uploadFile();
  },
  methods: {
    calcFileSizeString () {
      // calc kB
      let s = this.file.size / 1024;
      if (s < 1000) return '(' + Math.round(s) + 'kB)';
      // calc MB
      s = Math.round(s / 1024 * 100) / 100;
      return '(' + s + 'MB)';
    },
    async uploadFile () {
      this.parts = [];
      // generate objectKey
      this.s3objKey = this.objectKey;
      // Replace placeholders
      let generated = uuidv4(); // + '-' + Date.now();
      this.s3objKey = this.s3objKey.replace(/##FILENAME##/g, this.file.name);
      this.s3objKey = this.s3objKey.replace(/##GENERATED##/g, generated);
      try {
        // check if multipartUpload is needed
        if (this.chunkConfig != null && this.file.size > this.chunkConfig.chunkSize) {
          await this.multipartUpload();
        } else {
          await this.singleUpload();
        }
        this.$emit('uploadSuccess', this.file, this.s3objKey);
      } catch (err) {
        this.$emit('uploadError', err);
      }
    },
    async singleUpload () {
      return new Promise(async (resolve, reject) => {
      // prepare partSettings
        this.$logger.debug('dropzone', 'start singleUpload for', this.file);
        this.totalParts = 1;
        let part = {
          partNumber: 1,
          start: 0,
          end: this.file.size,
          size: this.file.size,
          url: '',
          eTag: '',
          progress: 0
        };
        this.parts.push(part);
        // get signedUrl
        try {
          part.url = await S3Helper.getSignedURL('putObject', this.s3objKey, this.s3Config);

          this.$logger.debug('dropzone', 'getSignedUrl', this.s3objKey, JSON.stringify(part, null, 2));
        } catch (err) {
          this.$logger.error('dropzone', 'getSignedUrls error: ' + err.message, { obj: this.s3objKey, config: this.s3Config });
          return reject(err);
        }
        // upload file via axios.put
        try {
          part.eTag = await this.tryUpload(part, false);

          this.$logger.debug('dropzone', 'uploadFile', JSON.stringify(part, null, 2));
        } catch (err) {
          this.$logger.error('dropzone', 'uploadFile error: ' + err.message);
          return reject(err);
        }
        // resolve success
        resolve(true);
      });
    },
    async multipartUpload () {
      return new Promise(async (resolve, reject) => {
      // prepare multipartSettings
        this.$logger.debug('dropzone', 'start multipartUpload for', this.file);
        this.totalParts = Math.ceil(this.file.size / this.chunkConfig.chunkSize);
        for (let i = 0; i < this.totalParts; i++) {
          let part = {
            partNumber: i + 1,
            start: i * this.chunkConfig.chunkSize,
            end: (i + 1) * this.chunkConfig.chunkSize,
            size: (i + 1) * this.chunkConfig.chunkSize > this.file.size ? this.file.size - i * this.chunkConfig.chunkSize : this.chunkConfig.chunkSize,
            url: '',
            eTag: '',
            progress: 0
          };
          this.parts.push(part);
        }
        // init S3 multipart Upload
        try {
          this.uploadId = await S3Helper.initMultipartUpload(this.s3objKey, this.s3Config);
          // this.$logger.debug('dropzone', 'initMultipartUpload', this.s3objKey, this.uploadId);
        } catch (err) {
          this.$logger.error('dropzone', 'initMultipartUpload error: ' + err.message, { obj: this.s3objKey, config: this.s3Config });
          return reject(err);
        }
        // get signedUrl for each part
        try {
          let getSignedUrlPromises = [];

          for (let part of this.parts) {
            let s3params = {
              PartNumber: part.partNumber,
              UploadId: this.uploadId
            };
            getSignedUrlPromises.push(S3Helper.getSignedURL('uploadPart', this.s3objKey, this.s3Config, s3params));
          }

          let signedUrls = await Promise.all(getSignedUrlPromises);
          for (let i = 0; i < this.parts.length; i++) {
            this.parts[i].url = signedUrls[i];
          }

          // this.$logger.debug('dropzone', 'getSignedUrls', JSON.stringify(this.parts, null, 2));
        } catch (err) {
          this.$logger.error('dropzone', 'getSignedUrls error: ' + err.message, { obj: this.s3objKey, config: this.s3Config });
          return reject(err);
        }
        // upload each part via axios.put
        try {
          let uploadPartPromises = [];

          for (let part of this.parts) {
            uploadPartPromises.push(this.tryUpload(part, true));
          }

          const eTags = await Promise.all(uploadPartPromises);

          for (let i = 0; i < this.parts.length; i++) {
            this.parts[i].eTag = eTags[i];
          }

          // this.$logger.debug('dropzone', 'uploadParts', JSON.stringify(this.parts, null, 2));
        } catch (err) {
          this.$logger.error('dropzone', 'uploadParts error' + err.message);
          return reject(err);
        }
        // complete MultipartUpload
        let multipartData = [];
        try {
          for (let part of this.parts) {
            multipartData.push({ PartNumber: part.partNumber, ETag: part.eTag });
          }

          await S3Helper.completeMultipartUpload(this.uploadId, this.s3objKey, this.s3Config, multipartData);
          this.$logger.debug('dropzone', 'completed MultipartUpload', this.uploadId, this.s3objKey);
        } catch (err) {
          this.$logger.error('dropzone', 'completeMultipartUpload error' + err.message, multipartData);
          return reject(err);
        }
        // resolve success
        resolve(true);
      });
    },
    async tryUpload (metaData, multipart) {
      return new Promise(async (resolve, reject) => {
        let tries = 1;
        while (tries <= this.maxTries) {
          try {
            let eTag = await this.axiosUpload(metaData, multipart, tries);
            tries = this.maxTries + 1;
            resolve(eTag);
          } catch (err) {
            if (tries > this.maxTries) {
              reject(err);
            }
            tries++;
          }
        }
      });
    },
    axiosUpload (metaData, multipart, tries = 1) {
      this.$logger.debug('dropzone', 'axiosUpload Part', metaData.partNumber, 'try', tries);
      return new Promise(async (resolve, reject) => {
        let blob = null;
        if (multipart) {
          blob = metaData.end < this.file.size
            ? this.file.slice(metaData.start, metaData.start + metaData.size)
            : this.file.slice(metaData.start);
        }

        let s3drop = this;
        axios.put(metaData.url, multipart ? blob : this.file, {
          onUploadProgress: function (progressEvent) {
            if (progressEvent.lengthComputable) {
              metaData.progress = progressEvent.loaded;
              let totalProgress = 0;
              for (let part of s3drop.parts) {
                totalProgress += part.progress;
              }
              s3drop.progress = totalProgress / s3drop.file.size * 100;
            }
          }
        }).then((response) => {
          this.$logger.debug('dropzone', 'axiosUpload response', response);
          if (response.status !== 200) throw new Error('invalid response status: ' + response.status);
          resolve(response.headers.etag);
        }).catch((err) => {
          this.$logger.debug('dropzone', 'axiosUpload error', err.message);
          if (tries < this.maxTries) { return this.axiosUpload(metaData, multipart, tries + 1); }
          reject(err);
        });
      });
    },
    cancel () {
      this.$emit('cancel', this.file);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input {
  ::v-deep div.v-input__control {
    div.v-input__slot {
      margin-top: 4px;
      margin-bottom: 0 !important;
    }
    div.v-messages {
      display: none;
    }
  }
}
</style>
